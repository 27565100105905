<template>
  <v-app>
    <!-- color bandeau #ED6B00 -->
    <v-app-bar
        app
        color="#FF9F51"
        dark
    >
      <div class="d-flex align-center">
        <v-img
            alt="Cita Logo"
            class="shrink mr-2"
            contain
            src="/icons/icon-192x192.png"
            transition="scale-transition"
            width="40"
        />
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <v-img
            alt="Logo application"
            class="shrink mr-2"
            contain
            src="/img/logo_transparent.png"
            transition="scale-transition"
            width="115"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
          href="https://www.citadelle.com/"
          target="_blank"
          text
      >
        <span class="mr-2">La Citadelle</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-row v-if="loading">
        <v-progress-circular size="70" indeterminate color="orange" class="ma-auto"></v-progress-circular>
      </v-row>
           <component :is="currentComponent" v-on:set-result="setResult" v-on:go-back="goBack"/> 
    </v-main>

    <v-footer app
      color="#FF9F51"
      dark
    >
      <v-btn text @click="goToPlan">
        <span class="mr-2">Plan</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import MainPage from './components/MainPage.vue';
import Enigme1 from './components/Enigme1.vue';
import Enigme2 from './components/Enigme2.vue';
import Enigme3 from './components/Enigme3.vue';
import Enigme4 from './components/Enigme4.vue';
import Enigme5 from './components/Enigme5.vue';
import Enigme6 from './components/Enigme6.vue';
import Plan from './components/Plan.vue';
import GuessPage from './components/GuessPage.vue';


export default {
  name: 'App',
  components: {
    
    MainPage,Enigme1,Enigme2,Enigme3,Enigme4,Enigme5,Enigme6,Plan,GuessPage,
  },
  computed:{
    currentComponent(){
      return this.tabComponent[this.PageIndex].component;
    },
  },
  methods: {
    setResult(index){
      console.log(index)
      this.PageIndex=index
    },
    goToPlan(){
      this.lastIndex=this.PageIndex
      this.PageIndex=7
    },
    goBack(){
      this.PageIndex=this.lastIndex
    },
    async cachedAssets() {
      const cache_name = "CitaApp";
      const ASSETS = [
        "./img/citamixinternet.jpg",
        "/index.html",
        "/offline.html",
        "/"
      ];
      return new Promise((resolve,reject)=>{
        console.log("Installing custom assets");
        caches
            .open(cache_name)
            .then(cache => {
              return resolve(cache.addAll(ASSETS));
            })
            .catch(err => {
              console.log(err);
              return reject();
            })
            .finally(()=>{
              console.log("... installation finished");
            })
      })
    }
  },
  data: () => ({
    loading: true,
    PageIndex:0,
    lastIndex:0,
    tabComponent:[{component:MainPage},{component:Enigme1},{component:Enigme2},{component:Enigme3},
    {component:Enigme4},{component:Enigme5},{component:Enigme6},{component:Plan},{component:GuessPage}],
  }),
  created() {
    this.cachedAssets().finally(()=>{
      this.loading=false;
    })
  }
};
</script>
<style>
  *{
    font-family: 'Hubballi', cursive;
  }
</style>