<template>
  <v-container>
    <v-row justify="center" align="center" class="text-left">
      <v-col cols="4">
        <v-img
          src="/img/vauban portrait.png"
          class="my-3"
        />
      </v-col>
      <v-col cols="8">
        Bonjour,
        Vous me reconnaissez peut-être je suis Sébastien Le Prestre Vauban.
        J’ai construit le lieu où vous vous trouvez aujourd'hui et beaucoup d’autres en France.
        J’ai besoin de votre aide car j’ai été fait prisonnier dans un de ces lieux. Plusieurs indices ont été
        laissés à 6 endroits de la Citadelle de Besançon. Pour m’aider à m’échapper, vous devrez trouver
        les différents indices afin de connaitre la ville où je suis enfermé.
      </v-col>
      
    </v-row>
    <v-row class="text-left">
      <v-col cols="12">
            Sur votre feuille explicative se trouvent les caractéristiques de 6 des 12 sites majeurs que j’ai
            élaboré en France. Grâce aux indices que vous récolterez aux différents endroits, vous pourrez
            déduire où je me situe.     
      </v-col>
    </v-row>
    <v-row class="text-left">
      <v-col cols="12">
            Je compte sur vous ! 
      </v-col>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center" style="font-size: 20px">
             Enigmes:
          </th>
        </tr>
      </thead>
      <tbody> 
          <tr
            v-for="item in enigme"
            :key="item.index"
          >
            <td class="text-center"><v-btn color="#FF9F51" dark @click="$emit('set-result',item.index)">{{ item.name }}</v-btn></td>
          </tr>
        </tbody>
    </v-simple-table>
    <v-row class="text-center" style="margin-top:20px">
      <v-col cols="12">
        <v-btn style="margin-bottom=80px" color="green" dark @click="$emit('set-result',8)">Où pensez-vous que je suis <br> fait prisonnier ?</v-btn>     
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'MainPage',
    data: () => ({
      enigme: [
        {
          name: 'Pour mieux me trouver',
          index: 1,
        },
        {
          name: 'Observez, Trouvez, Ouvrez',
          index: 2,
        },
        {
          name: 'Suis-je bien caché ?',
          index: 3,
        },
        {
          name: 'Reconstituez-moi',
          index: 4,
        },
        {
          name: 'Il faut me déchiffrer',
          index: 5,
        },
        {
          name: 'Devines-moi',
          index: 6,
        },
      ],
    }),
  }
</script>