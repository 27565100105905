<template>
  <v-container>
    <v-row justify="center" align="center" class="text-left">
      <v-col cols="8">
        <p>Répondez à la devinette, pour obtenir l’indice !</p>
        <p>Localisation: Préau de l’aquarium</p>
      </v-col>
      <v-col cols="4">
        <v-img
          src="/img/vauban portrait.png"
          class="my-3"
        />
      </v-col>
    </v-row>
    <v-btn style="margin-bottom:80px" color="#FF9F51" dark @click="$emit('set-result',0)">retour menu</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'Enigme6',
    data: () => ({
    }),
  }
</script>