<template>
  <v-container>
    <v-row class="text-left">
      <v-col >
        <v-img
        max-height="90vh"
        max-width="100vw"
        contain
          src="/img/plan.jpg"
        />
      </v-col>
    </v-row>
    <v-btn style="margin-bottom:80px" color="#FF9F51" dark @click="$emit('go-back')">retour</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'Plan',
    data: () => ({
    }),
  }
</script>