<template>
  <v-container>
    <v-row justify="center" align="center" class="text-left">
      <v-col cols="8">
        <p></p>
        <p>En reconstituant le puzzle, un nouvel indice apparaitra.</p>
        <p>Localisation: Musée comtois, Cuisine (Niveau 2)</p>
      </v-col>
      <v-col cols="4">
        <v-img
          src="/img/vauban portrait.png"
          class="my-3"
        />
      </v-col>
    </v-row>
    <v-btn style="margin-bottom:80px" color="#FF9F51" dark @click="$emit('set-result',0)">retour menu</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'Enigme4',
    data: () => ({
    }),
  }
</script>