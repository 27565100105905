<template>
  <v-container>
    <v-row justify="center" align="center" class="text-left">
      <v-col cols="8">Dans quel site suis-je enfermé ?</v-col>
      <v-col cols="4">
        <v-img
          src="/img/vauban portrait.png"
          class="my-3"
        />
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="result===undefined">
      <v-col cols="6">
        <v-btn width="160px" height="60px" color="#FF9F51" dark @click="result=false">Mont-Dauphin</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn width="160px" height="60px" color="#FF9F51" dark @click="result=true">Arras</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn width="160px" height="60px" color="#FF9F51" dark @click="result=false">Mont-Louis</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn width="160px" height="60px" color="#FF9F51" dark @click="result=false">Besançon</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn width="160px" height="60px" color="#FF9F51" dark @click="result=false">Saint-Vaast-<br>la-Hougue</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn width="160px" height="60px" color="#FF9F51" dark @click="result=false">Villefranche-de-<br>Conflent</v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-alert type="success" v-if="result">
        Félicitation ! Vous avez trouvé la bonne réponse ! Grâce à vous je vais pouvoir me libérer !
        Merci de votre participation !
      </v-alert>
      <v-alert type="error" v-else>
        Mince ! Ce n’est pas la bonne réponse. Je suis condamné à rester enfermer !
        Merci de votre participation !
      </v-alert>
    </v-row>
    <v-row style="margin-top: 100px">
      <v-btn style="margin-bottom:80px" color="#FF9F51" dark @click="$emit('set-result',0)">retour menu</v-btn>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'GuessPage',
      data: () => ({
        result:undefined
      }),
    methods: {
      reponse (result){
        if(result===true){
          this.result = result
        }
      }
    }
  }  
</script>