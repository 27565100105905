<template>
  <v-container>
    <v-row justify="center" align="center" class="text-left">
      <v-col cols="8">
        <p>Des informations se sont glissées dans le message de Vauban, écoutez bien !</p>
        <p>Localisation : Moulin</p>
        </v-col>
      <v-col cols="4">
        <v-img  
          src="/img/vauban portrait.png"
          class="my-3"
        />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        
      </v-col>
    </v-row> -->
    <v-row class="text-center">
      <v-col cols="12" v-if="!result">
          <qrcode-stream @decode="onDecode" @init="onInit">
          </qrcode-stream>
          <v-alert type="error" v-if="error">{{error}}</v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="decode-result" v-if="result">
        <video width="100%" controls>
          <source src="/video/vauban_video3.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video> 
      </v-col>
    </v-row>
    <v-btn style="margin-top:90px;margin-bottom:80px" color="#FF9F51" dark @click="$emit('set-result',0)">retour menu</v-btn>
  </v-container>  
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
  export default {
    name: 'Enigme1',
    components: {
    QrcodeStream,
    },
    data: () => ({
      result:null,
      error:null,
    }),
    methods: {
    onDecode (result) {
      if(result==='QrCodeVideo'){
        this.result = result
      }
    },

    async onInit (promise) {
      try {
        await promise
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            this.error = "ERROR: you need to grant camera access permission"
          } else if (error.name === 'NotFoundError') {
            this.error = "ERROR: no camera on this device"
          } else if (error.name === 'NotSupportedError') {
            this.error = "ERROR: secure context required (HTTPS, localhost)"
          } else if (error.name === 'NotReadableError') {
            this.error = "ERROR: is the camera already in use?"
          } else if (error.name === 'OverconstrainedError') {
            this.error = "ERROR: installed cameras are not suitable"
          } else if (error.name === 'StreamApiNotSupportedError') {
            this.error = "ERROR: Stream API is not supported in this browser"
          } else if (error.name === 'InsecureContextError') {
            this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
          } else {
            this.error = `ERROR: Camera error (${error.name})`;
          }
        }
      }
    }
  }
</script>

